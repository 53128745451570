<template>
  <v-app>
    <h1>kjjkkj</h1>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
// const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({

  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
  },
  methods: {
  },
};
</script>
